import axios from 'axios';

const API_URL = process.env.VUE_APP_API+'receipt/';

class ReceiptService {
  getReceiptPrint(id, type) {
    return axios.post(API_URL + 'getReceiptPrint/', {"id": id, "type": type});
  }
}

export default new ReceiptService();
