import axios from 'axios';

const API_URL = process.env.VUE_APP_API+'statistics/';

class StatisticsService {
    getDetails(date_start, date_end) {
        return axios.post(API_URL + 'getDetails/', {"date_start": date_start, "date_end": date_end});
    }
}

export default new StatisticsService();