<template>
  <div class="overlay" :class="modal"></div>
  <div class="modal" :class="modal">{{ $t('statistics.secureCode') }}<input v-model="secure_code">
    <button @click="verify">Ok</button>
    <button @click="close_modal">{{ $t('statistics.close') }}</button>
  </div>
  <div v-if="view_receipt_id === ''">
    <div class="row controls">
      <div class="margin_div">
      </div>
      <div class="col">
        <Datepicker
            @update:modelValue="disableExport"
            v-model="date_start"
            :locale="$i18n.locale"
            autoApply
            :format="format"
            :previewFormat="format"
            :minDate="minDate"
            :maxDate="new Date()"
        >
          <template #clock-icon>{{ $t('statistics.setTime') }}<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="dp__icon"><path d="M16 1.333c-8.095 0-14.667 6.572-14.667 14.667s6.572 14.667 14.667 14.667c8.095 0 14.667-6.572 14.667-14.667s-6.572-14.667-14.667-14.667zM16 4c6.623 0 12 5.377 12 12s-5.377 12-12 12c-6.623 0-12-5.377-12-12s5.377-12 12-12z"></path><path d="M14.667 8v8c0 0.505 0.285 0.967 0.737 1.193l5.333 2.667c0.658 0.329 1.46 0.062 1.789-0.596s0.062-1.46-0.596-1.789l-4.596-2.298c0 0 0-7.176 0-7.176 0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"></path></svg>
          </template>
        </Datepicker>
      </div>
      <div class="col">
        <Datepicker
            v-model="date_end"
            :locale="$i18n.locale"
            autoApply
            :format="format"
            :previewFormat="format"
            :maxDate="maxDate"
        >
          <template #clock-icon>{{ $t('statistics.setTime') }}<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="dp__icon"><path d="M16 1.333c-8.095 0-14.667 6.572-14.667 14.667s6.572 14.667 14.667 14.667c8.095 0 14.667-6.572 14.667-14.667s-6.572-14.667-14.667-14.667zM16 4c6.623 0 12 5.377 12 12s-5.377 12-12 12c-6.623 0-12-5.377-12-12s5.377-12 12-12z"></path><path d="M14.667 8v8c0 0.505 0.285 0.967 0.737 1.193l5.333 2.667c0.658 0.329 1.46 0.062 1.789-0.596s0.062-1.46-0.596-1.789l-4.596-2.298c0 0 0-7.176 0-7.176 0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"></path></svg>
          </template>
        </Datepicker>
      </div>
      <div class="col">
        <button class="btn btn-success" @click="getDetails()">{{ $t('statistics.view') }}</button>
        <button class="btn btn-success" @click="downloadFile()" :disabled="!can_be_exported">{{ $t('statistics.export') }}</button>
        <button class="btn btn-success" @click="printReport()" :disabled="!can_be_exported">{{ $t('statistics.printReport') }}</button>
      </div>
      <!--div class="col"></div-->
      <!--div class="col"></div-->
      <div class="col">
        <input v-model="find_receipt" v-on:input="ProceedEntry">
        <button class="btn btn-success" @click="findReceipt()">{{ $t('statistics.find') }}</button>
      </div>
    </div>

    <h2 class="stat_hint">{{ $t('statistics.statistics') }}</h2>
    <vue-good-table
        :title="$t('statistics.overview')"
        :columns="stat_columns"
        :rows="stat"
        :sort-options="{
          enabled: false,
        }"
        compactMode
        id="stat_table">
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{$t('cashier.vgtOptions.empty')}}</div>
      </template>
      <template #table-column="props">
        <span>
          {{$t('statistics.stat_table_'+props.column.label)}}
       </span>
      </template>
    </vue-good-table>

    <h2 class="stat_hint" v-if="Object.keys(tables).length > 0">{{ $t('statistics.statistics_tbl') }}</h2>
    <vue-good-table
        v-if="Object.keys(tables).length > 0"
        :title="$t('statistics.overview')"
        :columns="stat_columns_tbl"
        :rows="stat_tbl"
        :sort-options="{
          enabled: false,
        }"
        compactMode
    >
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{$t('cashier.vgtOptions.empty')}}</div>
      </template>
      <template #table-column="props">
        <span>
          {{$t('statistics.stat_table_'+props.column.label)}}
       </span>
      </template>
    </vue-good-table>

    <h2 class="stat_hint">{{ $t('statistics.details') }}</h2>
    <vue-good-table
        :title="$t('statistics.overview')"
        :columns="columns"
        :rows="rows"
        :lineNumbers="true"
        compactMode
        row-style-class="dts_row"
        v-on:row-click="onRowClick"
        :pagination-options="{
         enabled: true,
         perPage: 50,
         perPageDropdown: [50, 100],
         nextLabel: $t('cashier.vgtOptions.nextLabel'),
         prevLabel: $t('cashier.vgtOptions.prevLabel'),
         rowsPerPageLabel: $t('cashier.vgtOptions.rowsPerPageLabel'),
         ofLabel: $t('cashier.vgtOptions.ofLabel'),
         pageLabel: $t('cashier.vgtOptions.pageLabel'), // for 'pages' mode
         allLabel: $t('cashier.vgtOptions.allLabel')
         }">
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{$t('cashier.vgtOptions.empty')}}</div>
      </template>
      <template #table-column="props">
        <span>
          {{$t('statistics.detail_table_'+props.column.label)}}
       </span>
      </template>
      <template #table-row="props">
        <span v-if="props.row.cashier_paid != props.row.cashier_accept && props.row.cashier_paid !==null">
          <span style="font-weight: bold; color: blueviolet;">{{check_translate(props.column.field, props.formattedRow[props.column.field])}}</span>
        </span>
        <span v-if="props.row.status == 'paid' && props.row.cashier_paid === props.row.cashier_accept">
          <span style="font-weight: bold; color: green;">{{check_translate(props.column.field, props.formattedRow[props.column.field])}}</span>
        </span>
        <span v-if="props.row.status == 'rejected'">
          <span style="font-weight: bold; color: red;">{{check_translate(props.column.field, props.formattedRow[props.column.field])}}</span>
        </span>
        <span v-if="props.row.status == 'not_paid'">
          <span style="font-weight: bold; color: limegreen;">{{check_translate(props.column.field, props.formattedRow[props.column.field])}}</span>
        </span>
        <span v-if="props.row.status == 'lost'">
          <span style="font-style: italic;">{{check_translate(props.column.field, props.formattedRow[props.column.field])}}</span>
        </span>
        <span v-if="props.row.status == 'not_played'">
          <span style="font-weight: bold;">{{check_translate(props.column.field, props.formattedRow[props.column.field])}}</span>
        </span>
        <span v-if="props.row.status == 'pending_confirmation'">
          <span style="font-weight: bold; color: #ff4033;">{{check_translate(props.column.field, props.formattedRow[props.column.field])}}</span>
        </span>
        <span  v-if="props.row.status == 'playing' && (props.row.cashier_paid == props.row.cashier_accept || props.row.cashier_paid === null)">
          {{check_translate(props.column.field, props.formattedRow[props.column.field])}}
        </span>
      </template>
    </vue-good-table>
  </div>
  <div id="view_receipt" v-if="view_receipt_bets.length !== 0">
    <span class="btp_hint">
      {{$t('statistics.receipt')}}{{view_receipt_id}} |
      {{$t('statistics.detail_table_Receipt login')}}: {{view_receipt_login}}
      | {{$t('statistics.detail_table_Receipt date')}}: {{view_receipt_date}}
      <span v-if="view_receipt_paid_date !== '-'">
        | {{$t('statistics.detail_table_Payout login')}}: {{view_receipt_paid_login}}
        | {{$t('statistics.detail_table_Payout date')}}: {{view_receipt_paid_date}}
      </span>
      <span v-if="view_receipt_reject_date !== '-'">
        | {{$t('statistics.detail_table_Reject login')}}: {{view_receipt_reject_login}}
        | {{$t('statistics.detail_table_Reject date')}}: {{view_receipt_reject_date}}
      </span>
    </span>
    <div class="bets_table_cont">
      <table class="bets_table">
        <thead>
        <tr>
          <th>{{ $t('statistics.event') }}</th>
          <th>{{ $t('statistics.bet') }}</th>
          <th>{{ $t('statistics.betValue') }}</th>
          <th>{{ $t('statistics.betStatus') }}</th>
          <th>{{ $t('statistics.payout') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="bet in view_receipt_bets" :key="bet.bet">
          <td>{{bet.public_id}}</td>
          <td>{{tBetName(bet.bet_name, bet.bet_need_translate)}}{{bet.bet_value_postfix}}</td>
          <td>{{bet.bet_sum}}</td>
          <td>{{$t('statistics.bet_status_'+bet.status)}}</td>
          <td>{{bet.payout}}</td>
        </tr>
        <tr v-if="view_receipt_status === 'not_paid'">
          <td colspan="5"><h2>{{$t('statistics.totalPayout')}} {{view_receipt_bets[0].total_payout}}</h2></td>
        </tr>
        </tbody>
      </table>

    </div>
    <div id="view_receipt_buttons">
      <button class="black_button receipt_buttons" @click="close_receipt_view">{{ $t('statistics.close') }}</button>
      <button class="black_button receipt_buttons" v-if="view_receipt_status === 'not_played' && view_receipt_owner === 'self' && view_receipt_cancel === 'y'" @click="check_protected('cancel', view_receipt_id, false)">{{ $t('statistics.cancelReceipt') }}</button>
      <button class="black_button receipt_buttons" v-if="view_receipt_status === 'not_paid' && (view_receipt_all_can_payout === 'y' || view_receipt_owner === 'self')" @click="check_protected('payout', view_receipt_id, false)">{{ $t('statistics.payout_receipt') }}</button>
      <button class="black_button receipt_buttons" v-if="view_receipt_status === 'not_paid' && (view_receipt_all_can_payout === 'y' || view_receipt_owner === 'self')" @click="check_protected('payout', view_receipt_id, true)">{{ $t('statistics.payoutPrint') }}</button>
      <button class="black_button receipt_buttons" @click="getReceiptPrint(view_receipt_id, 'copy')">{{ $t('statistics.printCopy') }}</button>
    </div>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {VueGoodTable} from "vue-good-table-next";
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {inject, ref} from "vue";
import StatisticsService from "../services/statistics.service";
import XLSX from 'xlsx';
import cashier from "@/services/cashier.service";
import receiptService from "@/services/receipt.service";

export default {
  name: "Statistics",
  setup() {
    const format = (date_start) => {
      const day = date_start.getDate();
      const month = date_start.getMonth() + 1;
      const year = date_start.getFullYear();
      const hour = ref(date_start.getHours());
      const minute = ref(date_start.getMinutes());

      if(hour.value < 10)
      {
        hour.value = '0'+hour.value;
      }

      if(minute.value < 10)
      {
        minute.value = '0'+minute.value;
      }

      return `${day}.${month}.${year} ${hour.value}:${minute.value}`;
    };

    const set_result = inject('set_result');
    const isUpBalance = inject('isUpBalance');
    const route = inject('route');
    const currentUser = inject('currentUser');
    const currency_symbol = inject('currency_symbol');
    const tables = inject('tables');

    return {
      format,
      set_result,
      isUpBalance,
      route,
      currentUser,
      currency_symbol,
      tables
    };
  },
  data() {
    return {
      date_start: '',
      date_end: '',
      status_filter: [],
      game_filter: [],
      stat_columns: [
        {
          label: 'Entered',
          field: 'sum_bets_all',
        },
        {
          label: 'Payout',
          field: 'sum_payout_all',
        },
        {
          label: 'Balance',
          field: 'balance',
        },
        {
          label: 'Pending payout',
          field: 'sum_payout_all_pend',
        },
        {
          label: 'Receipts',
          field: 'receipts_cnt',
        },
        {
          label: 'Receipts rejected',
          field: 'receipts_cnt_rej',
        }
      ],
      stat_columns_tbl: [
        {
          label: 'Total deposited (wallet)',
          field: 'in_wallet_formatted',
        },
        {
          label: 'Total deposited (cashier)',
          field: 'in_cashier_formatted',
        },
        {
          label: 'Total paid',
          field: 'out_formatted',
        },
        {
          label: 'Balance',
          field: 'tbl_balance',
        }
      ],
      rows: [],
      stat: [],
      stat_tbl: [],
      stat_filter: [],
      view_receipt_bets: [],
      view_receipt_id: '',
      view_receipt_status: '',
      view_receipt_protected: '',
      view_receipt_cancel: '',
      view_receipt_all_can_payout: '',
      find_receipt: '',
      find_receipt_id: '',
      find_receipt_sc: '',
      view_receipt_owner: '',
      view_receipt_date: '',
      view_receipt_paid_date: '-',
      view_receipt_reject_date: '-',
      view_receipt_login: '',
      view_receipt_paid_login: '-',
      view_receipt_reject_login: '-',
      modal: '',
      secure_code: '',
      secure_codes_arr: [],
      print_state: false,
      receipt_action: '',
      can_be_exported: false
    };
  },
  components: {
    Datepicker,
    VueGoodTable
  },
  methods: {
    getDetails() {
      let dstart=this.format(this.date_start)
      let dend=this.format(this.date_end)

      this.rows=[];
      this.stat=[];
      this.stat_tbl=[];

      StatisticsService.getDetails(dstart, dend).then(
          (response) => {
            this.stat=response.data.agr;
            this.stat_tbl=response.data.agr_tbl;
            this.rows=response.data.rows;

            this.getFiltersValues();

            if(response.data.agr[0].filter_start !== null)
            {
              this.can_be_exported = true;
            }
            else
            {
              this.can_be_exported = false;
            }
          },
          (error) => {
            this.content =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    getFiltersValues()
    {
      let stat = [];
      let game = [];

      if(this.rows.length > 0)
      {
        for (let row of this.rows)
        {
          let prep_stat = {};
          prep_stat.value = row.status;
          prep_stat.text = this.$t('statistics.receipt_status_'+row.status);

          if(stat.map(function(e) { return e.value; }).indexOf(row.status) === -1)
          {
            stat.push(prep_stat);
          }
          
          game.push(row.game_name);
        }
      }

      this.game_filter = [...new Set(game)];
      this.status_filter = [...new Set(stat)];
    },
    downloadFile()
    {
      let prep_detail_data = this.prepareDownloadData(this.columns, this.rows);
      let prep_stat_data = this.prepareDownloadData(this.stat_columns, this.stat);
      //const detail_data = XLSX.utils.json_to_sheet(prep_detail_data);
      //const stat_data = XLSX.utils.json_to_sheet(prep_stat_data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, {}, 'Statistics');
      XLSX.utils.sheet_add_json(wb.Sheets.Statistics, [{Entered: this.$t('statistics.export_period')+' '+this.date_start.toLocaleDateString("ru-RU")+' - '+this.date_end.toLocaleDateString("ru-RU")}], {header: ["Entered"], skipHeader: true, origin:-1});
      XLSX.utils.sheet_add_json(wb.Sheets.Statistics, [{Entered: this.$t('statistics.statistics')}], {header: ["Entered"], skipHeader: true, origin:-1});
      XLSX.utils.sheet_add_json(wb.Sheets.Statistics, this.translate_data(prep_stat_data, 'stat'), {origin:-1});
      XLSX.utils.sheet_add_json(wb.Sheets.Statistics, [{Entered: ''}, {Entered: this.$t('statistics.details')}], {header: ["Entered"], skipHeader: true, origin:-1});
      XLSX.utils.sheet_add_json(wb.Sheets.Statistics ,this.translate_data(prep_detail_data, 'detail'), {origin:-1});
      //XLSX.utils.book_append_sheet(wb, detail_data, 'Details');
      XLSX.writeFile(wb,'export.xlsx');
    },
    prepareDownloadData(header, data)
    {
      let ret = [];
      for(let row of data)
      {
        let row_ret = [];
        for(let col of header)
        {
          row_ret[col.label] = row[col.field];
        }
        ret.push(row_ret);
      }

      return ret;
    },
    viewReceipt(row)
    {
      cashier.getReceiptBets(row.id).then(
          (response) => {
            if(response)
            {
              this.view_receipt_bets = response.data;
              this.view_receipt_id = row.id;
              this.view_receipt_protected = row.is_protected;
              this.view_receipt_status = row.status;
              this.view_receipt_owner = row.receipt_owner;
              this.view_receipt_all_can_payout = row.all_can_payout;
              this.view_receipt_cancel = row.can_cancel
              this.view_receipt_date = row.check_data_tz;
              this.view_receipt_paid_date = row.payout_date_tz||'-';
              this.view_receipt_reject_date = row.reject_date_tz||'-';
              this.view_receipt_login = row.cashier_accept;
              this.view_receipt_paid_login = row.cashier_paid||'-';
              this.view_receipt_reject_login = row.cashier_reject||'-';
            }
          }
      );
    },
    onRowClick(params)
    {
      this.viewReceipt(params.row);
    },
    findReceipt()
    {
      cashier.getReceipt(this.find_receipt_id).then(
          (response) => {
            if(response)
            {
              let row = [];
              row['id'] = response.data[0].id;
              row['status'] = response.data[0].status;
              row['receipt_owner'] = response.data[0].receipt_owner;
              row['all_can_payout'] = response.data[0].all_can_payout;
              row['can_cancel'] = response.data[0].can_cancel;
              row['is_protected'] = response.data[0].is_protected;
              row['check_data_tz'] = response.data[0].check_data_tz;
              row['payout_date_tz'] = response.data[0].payout_date_tz||'-';
              row['reject_date_tz'] = response.data[0].reject_date_tz||'-';
              row['cashier_accept'] = response.data[0].cashier_accept;
              row['cashier_paid'] = response.data[0].cashier_paid||'-';
              row['cashier_reject'] = response.data[0].cashier_reject||'-';
              this.viewReceipt(row);
            }
          }
      );
    },
    close_receipt_view()
    {
      this.view_receipt_bets = [];
      this.view_receipt_id = '';
      this.view_receipt_status = '';
      this.view_receipt_protected = '';
      this.view_receipt_owner = '';
      this.view_receipt_all_can_payout = '';
      this.view_receipt_date = '';
      this.view_receipt_paid_date = '';
      this.view_receipt_reject_date = '';
    },
    payout_receipt(id, print)
    {
      cashier.payoutReceipt(id, this.secure_code).then(
          (response) => {
            if(!response.data.result)
            {
              alert('Cannot payout receipt: '+response.data.message);
            }
            else
            {
              this.close_receipt_view();
              this.getDetails();
              this.close_modal();

              if(print)
              {
                this.getReceiptPrint(id, 'payout');
              }
            }
          }
      );
    },
    cancel_receipt(id)
    {
      cashier.cancelReceipt(id, this.secure_code).then(
          (response) => {
            if(!response.data.result)
            {
              alert('Cannot cancel receipt: '+response.data.message);
            }
            else {
              this.close_receipt_view();
              this.getDetails();
              this.close_modal();
            }
          }
      );
    },
    getReceiptPrint (id, type) {
      receiptService.getReceiptPrint(id, type).then(
          (response) => {
            this.printDiv(response.data);
          },
          (error) => {
            this.content =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          })
    },
    printDiv(html) {
      let frame1 = document.createElement('iframe');
      frame1.name = "frame1";
      frame1.style.position = "absolute";
      frame1.style.top = "-1000000px";
      document.body.appendChild(frame1);
      let frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
      frameDoc.document.open();
      frameDoc.document.write('<html lang="en">');
      frameDoc.document.write('<style>');
      frameDoc.document.write(cashier.getQuillCss());
      frameDoc.document.write('</style>');
      frameDoc.document.write('<body>');
      frameDoc.document.write(html);
      frameDoc.document.write('</body></html>');
      frameDoc.document.close();
      setTimeout(function () {
        window.frames["frame1"].focus();
        window.frames["frame1"].print();
        document.body.removeChild(frame1);
      }, 500);
    },
    check_protected(action, id, print)
    {
      this.print_state = print;

      if(this.view_receipt_protected === 'y')
      {
        this.open_modal();
        this.receipt_action = action;
      }
      else
      {
        if(action === 'payout')
        {
          this.payout_receipt(this.view_receipt_id, this.print_state);
        }

        if(action === 'cancel')
        {
          this.cancel_receipt(this.view_receipt_id);
        }
      }
    },
    verify()
    {
      if(this.receipt_action === 'payout')
      {
        this.payout_receipt(this.view_receipt_id, this.print_state);
      }

      if(this.receipt_action === 'cancel')
      {
        this.cancel_receipt(this.view_receipt_id);
      }
    },
    open_modal()
    {
      this.modal = 'open';

      if(this.secure_codes_arr[this.find_receipt_id] !== undefined)
      {
        this.secure_code = this.secure_codes_arr[this.find_receipt_id];
      }
    },
    close_modal()
    {
      this.modal = '';
      this.print_state = false;
      this.receipt_action = '';
      this.secure_code = '';
    },
    ProceedEntry()
    {
      let curFS = this.find_receipt;
      let finFS = '';
      if(!isNaN(parseInt(curFS)))
      {
        this.find_receipt_id = parseInt(curFS);
      }

      finFS = this.find_receipt_id;

      let csPrep = curFS.split('-');

      if(csPrep[1] !== undefined)
      {
        finFS = finFS.toString()+'-'+csPrep[1];
      }
      else
      {
        finFS = curFS;
      }

      if(curFS.length >= 11)
      {
        if(csPrep[1] !== undefined && csPrep[1].length === 6)
        {
          this.secure_codes_arr[this.find_receipt_id] = csPrep[1];
        }
      }

      finFS = finFS.replace(/^0+/g, '');
      this.find_receipt = finFS;
    },
    check_translate(field, text)
    {
      if(field === 'status')
      {
        return this.$t('statistics.receipt_status_'+text);
      }

      return text;
    },

    translate_data(data, table)
    {
      let ret_data = [];

      for(let row of data)
      {
        let ret_row = [];

        for (const [key, value] of Object.entries(row))
        {
          ret_row[this.$t('statistics.'+table+'_table_'+key)] = this.check_translate(key.toLowerCase(), value);
        }

        ret_data.push(ret_row);
      }

      return ret_data;
    },
    tBetName(name, ntt)
    {
      if(ntt === 'n')
      {
        return name;
      }

      if(this.$t('cashier.betName.'+name) !== 'cashier.betName.'+name)
      {
        return this.$t('cashier.betName.'+name);
      }
      return name;
    },
    printReport()
    {
      let login = '<div>'+this.currentUser.username+'</div>';
      let ex_time = '<div>'+this.stat[0].execute_time+'</div>';
      let ranges = '<div>'+this.stat[0].filter_start+' - '+this.stat[0].filter_end+'</div>';
      let cs = this.currency_symbol;
      let entered = this.stat[0].sum_bets_all;
      let payout = this.stat[0].sum_payout_all;
      let rec_rej = this.stat[0].receipts_cnt_rej;
      let balance = this.stat[0].balance;
      let payout_pend = this.stat[0].sum_payout_all_pend;
      let receipts_cnt = this.stat[0].receipts_cnt;
      let total_deposited_w = this.stat_tbl[0].in_wallet;
      let total_deposited_c = this.stat_tbl[0].in_cashier;
      let total_deposited_b = this.stat_tbl[0].in_bonus;
      let total_paid = this.stat_tbl[0].out;
      let balance_all = this.stat_tbl[0].balance_all;

      if(entered === null)
      {
        entered = '0';
      }

      if(payout === null)
      {
        payout = '0';
      }

      if(payout_pend === null)
      {
        payout_pend = '0';
      }

      let html = '';

      if(Object.keys(this.tables).length === 0)
      {
        html = '<table style="width: 71mm;" cellpadding="2" cellspacing="0">';
        html += '<tr style="width: 71mm;"><td align="center" colspan="2"><b>'+login+'</b></td></tr>';
        html += '<tr style="width: 71mm;"><td align="center" colspan="2"><b>'+ex_time+'</b></td></tr>';
        html += '<tr style="width: 71mm;"><td align="center" style="width: 71mm; border-bottom: 1px solid black;" colspan="2">'+ranges+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="left" style="border-bottom: 1px solid black;">'+this.$t('statistics.stat_table_Entered')+'</td><td align="right" style="border-bottom: 1px solid black;">'+entered+' '+cs+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="left" style="border-bottom: 1px solid black;">'+this.$t('statistics.stat_table_Payout')+'</td><td align="right" style="border-bottom: 1px solid black;">'+payout+' '+cs+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="left" style="border-bottom: 1px solid black;">'+this.$t('statistics.stat_table_Pending payout')+'</td><td align="right" style="border-bottom: 1px solid black;">'+payout_pend+' '+cs+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="left" style="border-bottom: 1px solid black;">'+this.$t('statistics.stat_table_Receipts')+'</td><td align="right" style="border-bottom: 1px solid black;">'+receipts_cnt+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="left" style="border-bottom: 1px solid black;">'+this.$t('statistics.stat_table_Receipts rejected')+'</td><td align="right" style="border-bottom: 1px solid black;">'+rec_rej+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="left"><b>'+this.$t('statistics.stat_table_Balance')+'</b></td><td align="right"><b>'+balance+' '+cs+'</b></td></tr>';
        html += '</table>';
      }
      else
      {
        html = '<table style="width: 71mm;" cellpadding="2" cellspacing="0">';
        html += '<tr style="width: 71mm;"><td align="center" colspan="2"><b>'+login+'</b></td></tr>';
        html += '<tr style="width: 71mm;"><td align="center" colspan="2"><b>'+ex_time+'</b></td></tr>';
        html += '<tr style="width: 71mm;"><td align="center" style="width: 71mm; border-bottom: 1px solid black;" colspan="2">'+ranges+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="center" colspan="2"><td>&nbsp;</td></tr>';
        html += '<tr style="width: 71mm;"><td align="center" colspan="2"><b>Virtual games</b></td></tr>';
        html += '<tr style="width: 71mm;"><td align="left" style="border-bottom: 1px solid black;">'+this.$t('statistics.stat_table_Entered')+'</td><td align="right" style="border-bottom: 1px solid black;">'+entered+' '+cs+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="left" style="border-bottom: 1px solid black;">'+this.$t('statistics.stat_table_Payout')+'</td><td align="right" style="border-bottom: 1px solid black;">'+payout+' '+cs+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="left" style="border-bottom: 1px solid black;">'+this.$t('statistics.stat_table_Pending payout')+'</td><td align="right" style="border-bottom: 1px solid black;">'+payout_pend+' '+cs+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="left" style="border-bottom: 1px solid black;">'+this.$t('statistics.stat_table_Receipts')+'</td><td align="right" style="border-bottom: 1px solid black;">'+receipts_cnt+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="left" style="border-bottom: 1px solid black;">'+this.$t('statistics.stat_table_Receipts rejected')+'</td><td align="right" style="border-bottom: 1px solid black;">'+rec_rej+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="center" colspan="2"><td>&nbsp;</td></tr>';
        html += '<tr style="width: 71mm;"><td align="center" colspan="2"><b>Tables</b></td></tr>';
        html += '<tr style="width: 71mm;"><td align="left" style="border-bottom: 1px solid black;">total deposited (wallet)</td><td align="right" style="border-bottom: 1px solid black;">'+total_deposited_w+' '+cs+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="left" style="border-bottom: 1px solid black;">total deposited (cashier)</td><td align="right" style="border-bottom: 1px solid black;">'+total_deposited_c+' '+cs+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="left" style="border-bottom: 1px solid black;">total deposited (bonus)</td><td align="right" style="border-bottom: 1px solid black;">'+total_deposited_c+' '+cs+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="left" style="border-bottom: 1px solid black;">total paid</td><td align="right" style="border-bottom: 1px solid black;">'+total_paid+' '+cs+'</td></tr>';
        html += '<tr style="width: 71mm;"><td align="center" colspan="2"><td>&nbsp;</td></tr>';
        html += '<tr style="width: 71mm;"><td align="left"><b>'+this.$t('statistics.stat_table_Balance')+'</b></td><td align="right"><b>'+balance_all+' '+cs+'</b></td></tr>';
        html += '</table>';
      }


      this.printDiv(html);
    },
    disableExport()
    {
      this.can_be_exported = false;
    }
  },
  mounted() {
    this.date_start=ref(new Date());
    this.date_start.setHours(0, 0, 0, 0);
    let cday=new Date();
    this.date_end=ref(new Date(cday.setDate(cday.getDate()+1)));
    this.date_end.setHours(0, 0, 0, 0);
    this.getDetails();
  },
  computed: {
    columns() {
      return [
        {
          label: '#',
          field: 'id',
          filterOptions: {enabled: true, placeholder: this.$t('statistics.detail_table_#')}
        },
        {
          label: 'Game',
          field: 'game_name',
          filterOptions: {enabled: true, filterDropdownItems: this.game_filter, placeholder: this.$t('statistics.detail_table_Game')}
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {enabled: true, filterDropdownItems: this.status_filter, placeholder: this.$t('statistics.detail_table_Status')}
        },
        {
          label: 'Entered',
          field: 'sum_bets_formatted',
        },
        {
          label: 'Payout',
          field: 'sum_payout_formatted',
        },
        {
          label: 'Receipt login',
          field: 'cashier_accept',
        },
        {
          label: 'Receipt date',
          field: 'check_data_tz',
        },
        {
          label: 'Payout login',
          field: 'cashier_paid',
        },
        {
          label: 'Payout date',
          field: 'payout_date_tz',
        }
      ]
    },
    minDate()
    {
      let d = new Date();
      return new Date(d.setDate(d.getDate()-60));
    },
    maxDate()
    {
      let d = new Date();
      return new Date(d.setDate(d.getDate()+1));
    }
  }/*,
  watch: {
    isUpBalance: function() {
      if(this.route.name === 'statistics')
      {
        this.getDetails();
      }
    }
  }*/
}
</script>

<style scoped>
.controls
{
  padding: 5px;
  width: 100%;
}
.controls button
{
  margin-left: 10px;
}
.stat_hint
{
  margin-top: 30px;
}
.bets_table {
  width: 100%;
  border: 1px solid #dddddd;
  border-collapse: collapse;
}
.bets_table th {
  font-weight: bold;
  background: #efefef;
  border: 1px solid #dddddd;
}
.bets_table td {
  border: 1px solid #dddddd;
}
.black_button
{
  margin-top: 20px;
  margin-left: 20px;
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(#464d55, #25292e);
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 18px;
  height: 30px;
  justify-content: center;
  line-height: 1;
  outline: none;
  overflow: hidden;
  padding: 0 5px;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
}
.modal
{
  background-color: #CCCCCC;
  height: 100px;
  width: 200px;
  top: 45%;
  left: 43%;
  border: 3px solid;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: baseline;
}
.overlay
{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: #464d55;
}
.open
{
  display: block !important;
}
.margin_div
{
  width: 5%;
}
</style>